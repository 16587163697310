<template>
    <q-page padding>
      <ConversionApiGenerator />
    </q-page>
  </template>

  <script>
  import ConversionApiGenerator from '@/components/api/ConversionApiGenerator.vue';
  export default {
    name: 'ConversionsApi',
    components: { ConversionApiGenerator }
  };
  </script>

  <style></style>
