<template>
  <q-card flat class="justify-center creative">
    <q-card-section>
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('conversion_api') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('conversion_api')" exact />
      </q-breadcrumbs>
    </q-card-section>
    <q-card-section>
      <q-banner class="bg-info text-white q-mb-md" rounded>
        <q-item>
          <q-item-section top avatar>
            <q-icon name="mdi-information-outline" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label caption lines="3" class="text-white">
              Clique
              <a
                href="https://drive.google.com/file/d/11vt9tO0DsrnVJaWEPO3F_i2RjQx83qkJ/view"
                style="text-decoration: none"
                class="q-mt-md"
                target="_blank"
                >aqui</a
              >
              para saber mais.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-banner>
    </q-card-section>
    <q-separator spaced />
    <q-card-section class="row q-col-gutter-md">
      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.id" :label="$t('affiliate_id')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.id)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar ID </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar Token
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <date-input
          buttons
          outlined
          :label="$t('conversion_date')"
          :v-model="params.date"
          @update="(value) => (params.date = value)"
          clearable
          range
          today-btn
        />
        <div
          style="text-align: left; margin-bottom: 5px; margin-left: 10px; font-size: 14px; color: #555; opacity: 0.9"
        >
          O período (intervalo) deve ser de até 90 dias.
        </div>
      </div>

      <div class="col-6">
        <div class="row q-col-gutter-sm">
          <div class="col">
            <q-select
              v-model="params.mode"
              :options="[
                {
                  label: $t('JSON'),
                  value: 'JSON'
                },
                {
                  label: $t('CSV'),
                  value: 'CSV'
                }
              ]"
              :label="$t('conversion_mode')"
              outlined
            />
          </div>
        </div>
      </div>

      <div class="col-6">
        <select-request
          :v-model="channel"
          outlined
          :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${getProfiles.affiliate_id}`"
          option-label="name"
          option-value="id"
          :label="$t('select_channel')"
          clearable
          @update="(value) => (channel = value === null ? null : value.id)"
        />
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="channel" :label="$t('channel_id')"> </q-input>
      </div>
      <div class="col-6">
        <q-select
          v-model="campaign"
          :disable="!channel"
          option-label="campaign_name"
          :label="$t('select_campaign')"
          :options="campaignOptions"
          @input="(value) => (campaign_id = value ? value.campaign_id : null)"
          outlined
          clearable
        ></q-select>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="campaign_id" :label="$t('campaign_id')"> </q-input>
      </div>

      <div class="col-6">
        <div class="row q-col-gutter-sm">
          <div class="col">
            <q-select
              v-model="params.status"
              :options="[
                {
                  label: $t('approved'),
                  value: 2
                },
                {
                  label: $t('pending'),
                  value: 1
                },
                {
                  label: $t('refused'),
                  value: 0
                }
              ]"
              :label="$t('conversion_status')"
              outlined
              clearable
            />
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row q-col-gutter-sm">
          <div class="col">
            <q-select
              v-model="params.payment_status"
              :options="[
                {
                  label: $t('payment_awaiting_release'),
                  value: 1
                },
                {
                  label: $t('payment_released_for_request'),
                  value: 2
                },
                {
                  label: $t('payment_awaiting_appointment'),
                  value: 3
                },
                {
                  label: $t('payment_scheduled_payment'),
                  value: 4
                },
                {
                  label: $t('payment_done'),
                  value: 5
                },
                {
                  label: $t('payment_refused'),
                  value: 11
                }
              ]"
              :label="$t('payment_status')"
              outlined
              clearable
              :disable="params.status?.value === 0 || params.status?.value === 1"
            />
          </div>
        </div>
      </div>

      <div class="col-12">
        <q-input outlined readonly type="text" :value="getApiLink" :label="$t('conversion_api_url')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;

import affiliateService from '../../services/AffiliateService';
import AffiliateMixin from '../../mixins/AffiliateMixin';
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';
import AdvertiserService from '@/services/AdvertiserService';
import DateInput from '@/widgets/inputsGlobal/DateInput';

export default {
  components: { SelectRequest, DateInput },
  mixins: [affiliateService, AffiliateMixin, AdvertiserService],
  name: 'ConversionApiGenerator',
  data() {
    return {
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      campaignOptions: [],
      apiDataUrl: dataLink,
      campaign: null,
      campaign_id: null,
      channel: null,
      login: null,
      params: {
        date: null,
        mode: [],
        status: '',
        payment_status: ''
      }
    };
  },
  watch: {
    channel() {
      this.setupCampaigns();
    }
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    },
    getApiLink: {
      get() {
        if (this.params.date && this.params.mode.value) {
          const [dateStart, dateEnd] = this.params.date.split(' - ');

          const convertDate = (dateString) => {
            const [day, month, year] = dateString.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
          };

          const formattedDateStart = convertDate(dateStart);
          const formattedDateEnd = convertDate(dateEnd);

          const params = {
            token: this.affiliateData.token,
            mode: this.params.mode.value,
            affid: this.affiliateData.id,
            canalid: this.channel,
            dateStart: formattedDateStart,
            dateEnd: formattedDateEnd,
            pid: this.campaign_id,
            conversionstatus: this.params.status ? this.params.status.value : null,
            paymentstatus: this.params.payment_status ? this.params.payment_status.value : null
          };

          const queryParams = Object.entries(params)
            .filter(([, value]) => value !== null && value !== undefined)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');

          return `${this.apiDataUrl}/conversoes?v=3&${queryParams}`;
        } else {
          return null;
        }
      }
    }
  },

  methods: {
    async validateAndEncodeUrl(value) {
      if (value == '') this.encodedUrl = '';
      try {
        var url = new URL(value);
        if (url.protocol == 'https:') {
          this.encodedUrl = encodeURIComponent(url.toString());
        }
        return;
      } catch (error) {
        return;
      }
    },

    async setupCampaigns() {
      try {
        this.onLoading(true);
        const { data } = await this.listCampaignsBySite(this.channel);
        this.campaignOptions = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.getProfiles.affiliate_id);
        if (status === 200) {
          this.affiliateData = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },

  created() {
    this.setupAffiliateData();
  }
};
</script>

<style></style>
